<template>
  <b-modal
    id="modal__SpecificationUpdate"
    title="Update Specification"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Update Specification'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <SpecificationForm v-model="formFields" />
    <permissions
      v-if="auth.roles.includes('administrator')"
      ref="permissionsBox"
      hide-save-button="true"
      title="Permissions"
      node-type="Specification"
      :node-name="formFields.title"
      :node-id="selectedSpecObj.id"
    />
    <div class="mt-1 w-100 d-flex justify-content-between">
      <span class="font-small-3 text-muted">
        ID: <span class="select-all">{{ selectedSpecObj.id }}</span>
      </span>
      <span class="font-small-3 text-muted">
        Created By: <span class="select-all">{{ selectedSpecObj.created_by }}</span>
      </span>
    </div>
  </b-modal>
</template>

<script>
import store from '@/store'
import SpecificationForm from '@/views/Specifications/components/SpecificationForm.vue'
import Permissions from '@/components/Permissions/Permissions.vue'
import { ref, computed } from '@vue/composition-api'

export default {
  name: 'UpdateSpecificationModal',
  components: { SpecificationForm, Permissions },
  setup(props, context) {
    const loading = ref(false)
    const permissionsBox = ref(null)
    const formFields = ref({
      title: '',
      description: '',
      prefix: '',
    })
    const auth = computed(() => store.state.auth)
    const selectedSpecObj = ref({})
    const onShow = () => {
      selectedSpecObj.value = store.state.specification.selectedSpecObj
      const { title, description, prefix } = selectedSpecObj.value
      formFields.value = { title, description, prefix }
    }

    const onSubmit = () => {
      loading.value = true
      const result = permissionsBox.value?.savePermissions()
      store
        .dispatch('specification/updateSpecification', formFields.value)
        .then(data => {
          if (data) {
            context.root.$bvModal.hide('modal__SpecificationUpdate')
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      formFields,
      selectedSpecObj,
      auth,
      onShow,
      onSubmit,
      permissionsBox,
    }
  },
}
</script>
