<template>
  <b-modal
  id="import-requirements-modal"
  title="Import Requirements from CSV file"
  :size="modalSize"
  no-close-on-backdrop
  hide-footer
  @show="onShow"
>
  <div v-if="loading">
    <div class="modal-body">
      <h1 class="mt-4 font-medium-3 text-center">
        Loading Import Dropdown...
      </h1>
      <h5 class="text-center text-warning">
        <b-spinner />
      </h5>
    </div>
  </div>

  <form-wizard
    v-else
    color="#7367F0"
    :title="null"
    :subtitle="null"
    style="box-shadow: none"
    :finish-button-text="finishButtonText"
    back-button-text="Previous"
    @on-complete="bulkAddRequirements"
  >
    <!-- Step One : Select file -->
    <tab-content
      title="Select CSV"
      icon="feather icon-file-text"
      :before-change="fileValidator"
    >
      <b-form-group label="Select CSV/XLSX file to import" label-for="filePickerCSV">
        <b-form-file
          id="filePickerCSV"
          v-model="file"
          accept=".csv"
          invalid-feedback="test"
          :state="Boolean(file)"
          @input="fileLinked"
        />
      </b-form-group>
    </tab-content>

    <!-- Step Two : Choose the Specification to import to -->
    <tab-content
      title="Select Specification"
      icon="feather icon-clipboard"
      :before-change="changeModalSize"
    >
      <b-form-group label="Choose a target Specification" label-for="specificationPicker">
        <SpecificationPicker id="specificationPicker" v-model="specification" />
      </b-form-group>

      <b-form-group label="Or create a new one" label-for="btnOpenCreateSpecModal">
        <b-button
          id="btnOpenCreateSpecModal"
          variant="outline-success"
          class="mb-2"
          @click="createSpecificationModal"
        >
          Create Specification
        </b-button>
      </b-form-group>
    </tab-content>

    <!-- Step Three : Map CSV columns to Kompozition properties -->
    <tab-content
      title="Column Mapping"
      icon="feather icon-link"
    >
      <div v-if="file">
        <b-row>
          <b-col>
            <p class="text-primary font-weight-bold font-medium-4 text-center">
              CSV Column
            </p>
          </b-col>
          <b-col>
            <p class="text-primary font-weight-bold font-medium-4 text-center">
              Kompozition Property
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-50">
            <b-form-checkbox
              v-model="has_header_row"
              switch
              @change="autoBind"
            >
              Has header row
            </b-form-checkbox>
          </b-col>
        </b-row>

        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettingsMappings"
          style="max-height: 30vh"
        >
          <b-row v-for="({ property, column}, index) in fields" :key="`columns-${index}`" class="mb-1">
            <!-- CSV Data Columns -->
            <b-col>
              <b-input-group>
                <b-input-group-append v-if="!mandatory_properties.includes(property)">
                  <b-button
                    variant="flat-danger"
                    @click="deleteBinding(property)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="cursor-pointer text-danger"
                    />
                  </b-button>
                </b-input-group-append>
                <b-form-select :value="column" :options="columns" @input="val => fields[index].column = val" />
              </b-input-group>
            </b-col>

            <!-- Kompozition Property-->
            <b-col>
              <h5 v-if="mandatory_properties.includes(property)" class="text-capitalize">
                {{ property }}
              </h5>
              <b-form-select
                v-else
                :value="property"
                class="text-capitalize"
                :options="properties"
                :disabled="mandatory_properties.includes(property)"
                @input="val => fields[index].property = val"
              />
            </b-col>
          </b-row>
        </vue-perfect-scrollbar>

        <b-button variant="flat-success" size="sm" block @click="addBinding">
          Add property mapping <feather-icon icon="PlusIcon" />
        </b-button>
        <hr>
        <div class="mt-2">
          <span class="font-medium-2 font-weight-bold">Preview</span>
        </div>

        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          style="max-height: 40vh"
        >
          <b-table
            :items="requirements.slice(0,5)"
          />
        </vue-perfect-scrollbar>
      </div>
    </tab-content>
  </form-wizard>

  <CreateSpecificationModal @specification-created="specificationAdded" />
</b-modal>
</template>

<script>
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import CreateSpecificationModal from '@/views/Specifications/components/modals/CreateSpecModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BFormGroup } from 'bootstrap-vue'
import csv from 'jquery-csv'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-good-table/dist/vue-good-table.css'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions } from 'vuex'

export default {
  name: 'ImportCsvRequirementsModal',
  components: {
    BFormGroup,
    SpecificationPicker,
    FormWizard,
    TabContent,
    VuePerfectScrollbar,
    CreateSpecificationModal,
  },
  data() {
    return {
      file: null,
      csv_array: [],
      has_header_row: true,
      specification: null,
      hidden_properties: ['spec_id', 'specification', 'id'],
      mandatory_properties: ['display_id', 'object_text', 'priority'],
      requirement_fields: [],
      bulk_create_loading: false,
      modalSize: 'lg',
      get_default_spec: true,
      loading: false,
      fields: [
        {
          property: 'display_id',
          column: null,
        },
        {
          property: 'object_text',
          column: null,
        },
        {
          property: 'priority',
          column: null,
        },
      ],
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
      perfectScrollbarSettingsMappings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
        suppressScrollX: true,
      },
    }
  },
  computed: {
    properties() {
      return this.requirement_fields.filter(field => !this.hidden_properties.includes(field)).map(field => Object({
        value: field,
        text: field.replaceAll('_', ' '),
        disabled: this.fields.map(({ property }) => property).includes(field),
      }))
    },
    columns() {
      const mapFunction = (text, value) => (this.has_header_row ? { value, text } : { value, text: `Column - ${value}` })
      if (!this.csv_array[0]) return []
      return [{ value: null, text: 'No Value' }, ...this.csv_array[0].map(mapFunction)]
    },
    requirements() {
      const requirements = []
      if (this.specification !== null) {
        this.csv_array.forEach((row, index) => {
          if (this.has_header_row && index === 0) {
            return
          }
          const requirement = {}
          this.fields.forEach(({ property, column }) => {
            requirement[property] = row[column] || null
          })
          requirements.push(requirement)
        })
      }
      return requirements
    },
    finishButtonText() {
      if (this.bulk_create_loading) {
        return 'Importing CSV Requirements...'
      }
      return `Import ${this.requirements.length} Requirements`
    },
  },
  watch: {
    specification: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.getRequirementAttributes()
        }
      },
    },
  },
  methods: {
    ...mapActions({
      bulkAddRequirements_vuex: 'requirements/bulkAddRequirement',
    }),
    onShow() {
      this.loading = true
      this.getRequirementAttributes()
      if (this.$store.state.requirementsTableLegacy.specification) {
        this.specification = this.getSpecificationById(this.$store.state.requirementsTableLegacy.specification)
      }
      this.loading = false
    },
    specificationAdded(spec) {
      this.specification = null
    },
    fileValidator() {
      return new Promise((resolve, reject) => {
        if (this.file) {
          return resolve(true)
        }
        return reject()
      })
    },
    createSpecificationModal() {
      this.$bvModal.show('modal__SpecificationCreate')
    },
    changeModalSize() {
      this.modalSize = 'xl'
      return true
    },
    getRequirementAttributes() {
      if (this.specification !== null && this.specification) {
        const params = {
          model: this.$store.state.model.id,
          specification: this.specification.id,
          user_upload: true,
        }
        this.$http
          .get('/api/v2/requirements/attributes', { params })
          .then(({ data }) => {
            this.requirement_fields = data.attrs
          })
      }
    },
    bulkAddRequirements() {
      this.bulk_create_loading = true
      this.bulkAddRequirements_vuex({ requirements: this.requirements, specification: this.specification.id }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${this.has_header_row ? this.csv_array.length - 1 : this.csv_array.length} Requirements Added`,
            icon: 'CheckIcon',
            text: 'Requirements Added',
            variant: 'success',
          },
        })
        this.bulk_create_loading = false
        setTimeout(() => { this.$store.dispatch('requirementsTableLegacy/getTable') }, 500)
        this.$bvModal.hide('import-requirements-modal')
      })
    },
    autoBind() {
      if (this.has_header_row) {
        const props = this.properties.filter(({ value }) => !this.fields.filter(({ column }) => column).map(({ property }) => property).includes(value))
        this.columns.forEach(col => {
          const prop = props.find(({ text }) => col.text.toLowerCase() === text.toLowerCase())
          if (prop) {
            const existingProp = this.fields.find(({ property }) => property === prop.value)
            if (existingProp) {
              this.fields.find(({ property }) => property === prop.value).column = col.value
            } else {
              this.fields.push({
                property: prop.value,
                column: col.value,
              })
            }
          }
        })
      }
    },
    addBinding() {
      const nextProperty = this.requirement_fields.filter(field => !this.hidden_properties.includes(field)).find(field => !this.fields.map(({ property }) => property).includes(field))
      this.fields.push({
        property: nextProperty,
        column: null,
      })
    },
    deleteBinding(delProperty) {
      this.fields = this.fields.filter(({ property }) => property !== delProperty)
    },
    browserSupportFileUpload() {
      let isCompatible = false
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        isCompatible = true
      }
      return isCompatible
    },
    fileLinked() {
      if (!this.browserSupportFileUpload()) {
        alert('The File APIs are not fully supported in this browser!')
      } else {
        const reader = new FileReader()
        console.log(this.file)
        if (this.file) {
          reader.readAsText(this.file)
          reader.onload = evt => {
            this.csv_array = csv.toArrays(evt.target.result)
            if (this.has_header_row && this.csv_array[0]) {
              const newFields = this.csv_array[0].filter(colNm => !this.requirement_fields.includes(colNm.toLowerCase())).map(x => x.toLowerCase())
              this.requirement_fields = [...this.requirement_fields, ...newFields]
              this.autoBind()
            }
          }
        } else {
          this.csv_array = []
        }
      }
    },
  },
}
</script>
